@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
/* @import '~@fullcalendar/timegrid/main.css'; */

.fc {
	font-family: "Metric-Web-Regular", sans-serif;
}

.fc .fc-toolbar .fc-center > div {
	display: flex;
	align-items: center;
}

.fc .fc-toolbar .fc-button,
.fc .fc-toolbar .fc-button:active,
.fc .fc-toolbar .fc-button-active:focus,
.fc .fc-toolbar .fc-button:focus {
	background: none;
	box-shadow: none;
	border: none;
	color: #5e5e5e;
}

.fc .fc-toolbar button .fc-icon {
	font-size: 2.1em;
	font-weight: bold;
}

.fc .fc-toolbar button:focus {
	outline: 0 none;
}

.fc .fc-toolbar .fc-dayGridMonth-button,
.fc .fc-toolbar .fc-listWeek-button {
	font-size: 1.6em;
	font-weight: bold;
}

.fc .fc-toolbar .fc-dayGridMonth-button.fc-button-active,
.fc .fc-toolbar .fc-listWeek-button.fc-button-active {
	text-decoration: underline;
}

.fc .fc-toolbar h2 {
	font-size: 2em;
	font-family: 'Metric-Web-Black', sans-serif;
	line-height: 1.1;
	padding: 0 30px;
}

.fc .fc-toolbar h2,
.fc .fc-toolbar button {
	color: #5e5e5e;
}

.fc .fc-list-heading-main,
.fc .fc-list-heading-alt {
	padding: 8px;
	color: #5e5e5e;
	font-size: 1.5em;
	font-weight: 700;
	font-family: 'Metric-Web-Black', sans-serif;
}

.fc .fc-list-heading-main {
	float: left;
}

.fc .fc-list-item,
.fc .fc-list-item td,
.fc .fc-list-item:hover {
	background-color: #f4bd37 !important;
	cursor: pointer;
	border-color: #f4bd37;
	border-bottom-color: #fff;
	font-size: 1.1em;
}

.fc .fc-list-item-time,
.fc .fc-list-item-title {
	padding: 8px;
	color: #5e5e5e;
	font-size: 1em;
	font-weight: 700;
	font-family: 'Metric-Web-Black', sans-serif;
	color: #fff;
}

.fc .fc-list-item-title {
	text-align: right;
}

.fc .fc-list-heading-alt {
	float: right;
}

.fc .fc-list-empty {
	font-size: 1.5em;
}

.fc th {
	text-align: left;
	border: 0 none;
	color: #5e5e5e;
	padding-bottom: 8px;
}

/* Day (column) header */
.fc th.fc-day-header {
	padding: 8px;
	border: none;
}

.fc td {
	border-color: #d1d1d1;
}

.fc .fc-head td {
	border-left-color: #fff;
	border-right-color: #fff;
	border-top-color: #fff;
}

.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
	float: none;
	color: white;
	padding: 10px 8px;
	display: block;
	margin: 2px;
}

/* Day top (day number) - past  */
.fc td.fc-day-top.fc-past .fc-day-number {
	background: #f8f1e9;
}

/* Day top (day number) - past (other month)  */
.fc td.fc-day-top.fc-other-month {
	opacity: .7;
}

.fc td.fc-day-top.fc-other-month.fc-past .fc-day-number {
	background: #f8f1e9;
}

/* Day top (day number) - today  */
.fc td.fc-day-top.fc-today .fc-day-number {
	background: #263045;
}

.fc td.fc-today {
	background: #fff;
}

/* Day top (day number) - future  */
.fc td.fc-day-top.fc-future .fc-day-number {
	background-color: #f4bd37;
}

.fc .fc-event {
	background-color: #fff;
	border: none;
	cursor: pointer;
}

.fc .fc-event-header {
	padding: 8px;
	font-size: '1.5em';
}

.fc .fc-event-root {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #f4bd37;
	padding: 8px;
	font-size: 1.5em;
	/* height: 144px; */
}

.fc .fc-event-title {
	font-family: 'Metric-Web-Black', sans-serif;
	text-align: center;
}

/* .fc .fc-event-image {
    max-height: 90px;
} */

/* .fc .fc-event-content {} */
